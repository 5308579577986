.places {
  background-color: #d9dce1;
  padding: 5% 5%;

  .places_form {
    background-color: white;
    border-radius: 5px;
    padding: 2.5% 2.5%;
  }

  .row {
    margin-top: 3vh;
    height: 10vh;
  }

  .places_ok-button {
    background-color: #22c55e;
    color: white;
    border-radius: 5px;
    border: none;
    height: 4vh;
    width: 50%;
    font-size: 1rem;
    font-weight: 500;
    font-family: 'Nunito';
  }

  .places_search-button {
    background-color: $lightGrey;
    border-radius: 5px;
    border-color: $lightGrey;
    height: 4vh;
    width: 50%;
    font-size: 1rem;
    font-weight: 500;
    font-family: 'Nunito';
  }
}

//Dialog Add Place
.modal-container {
  color: #000;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-top: 0px;
  position: relative;
  width: 300px;
}
.modal-container .modal-btn {
  display: block;
  margin: 0 auto;
  color: #fff;
  width: 160px;
  height: 4vh;
  line-height: 4vh;
  background: #22c55e;
  font-family: 'Nunito';
  font-size: 1rem;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 5px 5px -5px #333;
  transition: background 0.3s ease-in;
}
.modal-container .modal-btn:hover {
  background: #199245;
}
.modal-container .modal-content,
.modal-container .modal-backdrop {
  height: 0;
  width: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  cursor: pointer;
  transition: opacity 0.2s ease-in;
}
.modal-content h2 {
  margin-bottom: 0;
}
.modal-container .modal-close {
  color: #aaa;
  position: absolute;
  right: 5px;
  top: 5px;
  padding-top: 3px;
  background: #fff;
  font-size: 16px;
  width: 25px;
  height: 25px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}
.modal-container .modal-close:hover {
  color: #333;
}
.modal-container .modal-content-btn {
  position: absolute;
  text-align: center;
  cursor: pointer;
  bottom: 20px;
  right: 30px;
  background: #22c55e;
  color: #fff;
  width: 80px;
  border-radius: 2px;
  font-size: 14px;
  height: 32px;
  padding-top: 9px;
  font-weight: normal;
  font-family: 'Nunito';
  font-size: 1rem;
  line-height: 2vh;
}
.modal-container .modal-content-btn:hover {
  color: #fff;
  background: #199245;
}
.modal-container .modal-content-btn-cancel {
  position: absolute;
  text-align: center;
  cursor: pointer;
  bottom: 20px;
  right: 120px;
  background: red;
  color: #fff;
  width: 80px;
  border-radius: 2px;
  font-size: 14px;
  height: 32px;
  padding-top: 9px;
  font-weight: normal;
  font-family: 'Nunito';
  font-size: 1rem;
  line-height: 2vh;
}

.modal-container .modal-content-btn-cancel:hover {
  color: #fff;
  background-color: #9e0000;
}

.modal-container #modal-toggle {
  display: none;
}
.modal-container #modal-toggle.active ~ .modal-backdrop,
.modal-container #modal-toggle:checked ~ .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s ease-in;
}
.modal-container #modal-toggle.active ~ .modal-content,
.modal-container #modal-toggle:checked ~ .modal-content {
  opacity: 1;
  background-color: #fff;
  max-width: 60vw;
  width: 60vw;
  height: 500px;
  padding: 10px 30px;
  position: fixed;
  left: 20%;
  top: 25%;
  border-radius: 4px;
  z-index: 999;
  pointer-events: auto;
  cursor: auto;
  visibility: visible;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.6);
}

.modal-container .modal-content-input {
  height: 32px;
  font-weight: normal;
  font-family: 'Nunito';
  font-size: 1rem;
  width: 100%;
  margin-top: 15px;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  border-bottom-color: $lightGrey;
}

.modal-container .modal-content-textarea {
  height: 96px;
  font-weight: normal;
  font-family: 'Nunito';
  font-size: 1rem;
  width: 100%;
  margin-top: 15px;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  border-bottom-color: $lightGrey;
  resize: none;
}
