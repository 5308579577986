.form {
  background-color: white;
  border-radius: 20px;
  -webkit-box-shadow: 5px 5px 20px 0px rgba(173, 173, 173, 1);
  -moz-box-shadow: 5px 5px 20px 0px rgba(173, 173, 173, 1);
  box-shadow: 5px 5px 20px 0px rgb(14, 17, 16);
  padding: 1.5em;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0;

  @media screen and (min-width: 768px) {
    padding: 2.5em;
  }
}

.h1 {
  text-align: center;
  font-size: 2.5rem;
  padding: 0;
  margin-bottom: 20px;
  color: white;
  filter: drop-shadow(0px 0px 3px black);
}

.h2 {
  text-align: center;
  font-size: 28px;
  padding: 0;
  margin-bottom: 20px;
}

@media screen and (min-width: 700px) {
  .large-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .special-group {
    width:100%;
    text-align: justify;
  }
  
  .special-group-2 {
    width: 90%;
  }

  .special-group-3 {
    width: 60%
  }

  .small-group {
    width: 30%;
  }

  .medium-group {
    width: 45%;
  }

  .mini-group {
    width: 22.5%;
  }

  .micro-group {
    width: 18%;
  }
}

.label {
  display: block;
  margin-bottom: 5px;
}

.input-select {
  padding: 10px 16px;
  width: 100%;
  outline: 0;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 1px solid rgba(201, 201, 201, 0.711);
  background-color: $placeInput-strong;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
}

.textarea-div {
  width: 100%;
}

.textarea {
  padding: 10px;
  width: 100%;
  outline: 0;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 1px solid rgba(201, 201, 201, 0.711);
  background-color: $placeInput-strong;
  font-size: 14px;
  height: 100px;
  resize: vertical;
}

.btn {
  background-color: #249889;
  padding: 12px 15px;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid transparent;
  border-radius: 20px;
  transition: 0.3s all ease;
  color: white;
  &:hover {
    background-color: #006b6b;
  }
}
.btn_extra {
  background-color: #dd940d;
  padding: 12px 15px;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid transparent;
  border-radius: 20px;
  transition: 0.3s all ease;
  color: white;
  &:hover {
    background-color: #a7700a;
  }
}
