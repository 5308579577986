.footer-distributed {
  background: linear-gradient(to right, #00544e, #009696);
  box-shadow: 0 1px 1px rgba(241, 166, 166, 0.12);
  box-sizing: border-box;
  color: white;
  width: 100vw;
  height: 13vh;
  text-align: left;
  font: bold 16px sans-serif;
  padding: 10px 60px;
  position: relative;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.footer-distributed .footer-left {
  display: inline;
  text-align: center;
}

.footer-distributed .footer-left {
  width: 40%;
}

.footer-distributed_logo-container {
  justify-content: center;
}

.footer-distributed_logo {
  margin-left: 0;
  width: 10vw;
  filter: drop-shadow(0px 0px 5px black);
}

.footer-distributed .footer-company-name {
  color: $white;
  font-size: 1rem;
  font-weight: normal;
  font-family: 'Nunito';
  margin: 0;
}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) and (min-width: 475px) {
  .footer-distributed {
    padding-top: 20px;
    font: bold 14px sans-serif;
    height: 15vh;
    vertical-align: middle;
  }

  .footer-distributed .footer-left {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .footer-distributed .footer-center i {
    margin-left: 0;
  }

  .footer-distributed_logo {
    margin-left: 0;
    width: 15vw;
    filter: drop-shadow(0px 0px 5px black);
  }
}

@media (max-width: 474px) {
  .footer-distributed {
    padding-top: 30px;
    font: bold 14px sans-serif;
    height: 20vh;
    vertical-align: middle;
  }

  .footer-distributed .footer-left{
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .footer-distributed .footer-center i {
    margin-left: 0;
  }

  .footer-distributed_logo {
    margin-left: 0;
    width: 20vw;
    filter: drop-shadow(0px 0px 5px black);
  }
}
