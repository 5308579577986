h1 {
  margin: 0;
  line-height: 2;
  width: 90%;
}
h2 {
  margin: 0 0 0.5em;
  font-weight: normal;
}
.inputview {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
// Layout
.row {
  display: flex;
  margin-top: 5em;

  .col {
    flex: 1;
    &:last-child {
      margin-left: 5vw;
      margin-right: 5vw;
    }
  }
}
/* Accordion styles */
.tabs {
  border-radius: 8px;
  overflow: hidden;
}
.tab {
  width: 90vw;
  color: white;
  overflow: hidden;
  &-label {
    display: flex;
    justify-content: center;
    padding: 1em;
    background: linear-gradient(to right, #00544e, #009696);
    font-weight: bold;
    font-size: 2rem;
    cursor: pointer;
    /* Icon */
    &:hover {
      //background: darken( #009696, 10%);
      background: linear-gradient(to right, #009696, #00544e);
    }
    &::after {
      //content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all 0.35s;
    }
  }
  &-label2 {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: linear-gradient(to right, #00544e, #009696);
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    /* Icon */
    &:hover {
      //background: darken( #009696, 10%);
      background: linear-gradient(to right, #009696, #00544e);
    }
    &::after {
      //content: "\276F";
      width: 1em;
      height: 0.5em;
      text-align: center;
      transition: all 0.35s;
    }
  }
  &-content {
    max-height: 0;
    padding: 0 1em;
    color: rgb(255, 255, 255);
    background: #00544e83;
    transition: all 0.35s;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Nunito';
  }
  &-text {
    max-height: 0;
    padding: 0 1em;
    color: white;
    background: #00544e83;
    transition: all 0.35s;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Nunito';
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: linear-gradient(to right, #009696, #00544e);
    cursor: pointer;
    &:hover {
      background: darken(#00544e, 10%);
    }
  }
}

// :checked
input:checked {
  + .tab-label {
    background: darken(#009696, 5%);
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: 500vh;
    padding: 1em;
  }
  ~ .tab-text {
    max-height: 100vh;
    padding: 1em;
  }
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 85%;
  table-layout: fixed;
  color: #00544e;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th {
  padding: 0.625em;
  text-align: center;
}
table td {
  padding: 0.35em;
  text-align: center;
}

table th {
  font-size: 0.8em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: #dec65e;
  color: #00544e;
}

@media screen and (max-width: 1075px) {
  h1 {
    line-height: 2;
    text-align: center;
  }
  text {
    max-height: 0;
    padding: 0 1em;
    color: white;
    background: #00544e83;
    transition: all 0.35s;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Nunito';
    width: 85%;
  }
  table {
    border: 0;
    width: 80%;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

@media screen and (max-width: 360px) {
  .tab {
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: rgb(255, 255, 255);
      background: #00544e83;
      transition: all 0.35s;
      font-size: 0.85em;
      font-weight: 600;
      font-family: 'Nunito';
      text-align: left;
    }
  }
  h1 {
    line-height: 2;
    text-align: left;
    font-size: 1.5em;
  }
}
