p {
	text-align: center;
}

.toggle,
[id^=drop] {
	display: none;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
	margin:0;
	padding: 0;
	background: linear-gradient(to right, #00544e, #009696);
  //position: absolute;
}

#logo {
	display: block;
	padding: 0 30px;
	float: left;
	font-size:20px;
	line-height: 60px;
  margin-top: 5px;
  filter: drop-shadow(0px 0px 5px black);
}

nav:after {
	content:"";
	display:table;
	clear:both;
}

nav ul {
	padding: 0;
  margin: 0;
	list-style: none;
	position: relative;
  float: right;
  z-index: 150;
	}
	
nav ul li {
	margin: 0px;
	display:inline-block;
	
	background-color: linear-gradient(to right, #00544e, #009696);
	}


nav a {
	display:block;
	padding:0 10px;	
	color:#FFF;
	margin-top: 0;
	line-height: 60px;
	text-decoration:none;
}

nav a .toggle {
  display:block;
	padding:0 10px;	
	color:#FFF;
	margin-top: 0;
	//line-height: 60px;
	text-decoration:none;
}

nav ul li ul li:hover { background: #000000; }

nav a:hover { 
	background-color: #DEC65E; 
  color: #00544e;
}

/* Hide Dropdowns by Default */
nav ul ul {
	display: none;
	position: absolute; 
	top: 60px; /* the height of the main nav */
}
	
/* Display Dropdowns on Hover */
nav ul li:hover > ul {
	display:inherit;
}
	
/* Fisrt Tier Dropdown */
nav ul ul li {
	width:170px;
	float:none;
	display:list-item;
	position: relative;
  background: linear-gradient(to right, #00544e, #009696);
}

/* Second, Third and more Tiers	*/
nav ul ul ul li {
	position: relative;
	//top:-60px; 
	left:170px;
}

	
/* Change this in order to change the Dropdown symbol */
li > a:after { content:  ' +'; }
li > a:only-child:after { content: ''; }

@media all and (max-width : 768px) {

	#logo {
		display: block;
		padding: 0;
		width: 100%;
		text-align: center;
		float: none;
    margin-top: 0px;
	}

	nav {
		margin: 0;
    padding-top: 10px;
	}

	/* Hide the navigation menu by default */
	/* Also hide the  */
	.toggle + a,
	.menu {
		display: none;
	}

	/* Stylinf the toggle lable */
	.toggle {
		display: block;
		
		padding:14px 20px;	
		color:#FFF;
		font-size:17px;
		text-decoration:none;
		border:none;
	}

	.toggle:hover {
		background-color: #DEC65E; 
    color: #00544e;
	}

	/* Display Dropdown when clicked on Parent Lable */
	[id^=drop]:checked + ul {
		display: block;
	}

	/* Change menu item's width to 100% */
	nav ul li {
		display: block;
		width: 100%;
    float: left;
		}

	nav ul ul .toggle,
	nav ul ul a {
		padding: 0 40px;
	}

	nav ul ul ul a {
		padding: 0 80px;
	}

	nav a:hover,
 	nav ul ul ul a {
		background-color: #DEC65E; 
    color: #00544e;
	}
  
	nav ul li ul li .toggle,
	nav ul ul a,
  nav ul ul ul a{
		padding:14px 20px;	
		color:#FFF;
		font-size:17px; 
	}
  
  
	nav ul li ul li .toggle,
	nav ul ul a {
		background-color: #212121; 
	}

	/* Hide Dropdowns by Default */
	nav ul ul {
		float: none;
		position:static;
		color: #ffffff;
		/* has to be the same number as the "line-height" of "nav a" */
	}
		
	/* Hide menus on hover */
	nav ul ul li:hover > ul,
	nav ul li:hover > ul {
		display: none;
	}
		
	/* Fisrt Tier Dropdown */
	nav ul ul li {
		display: block;
		width: 100%;
	}

	nav ul ul ul li {
		position: static;
		/* has to be the same number as the "width" of "nav ul ul li" */ 

	}

}

@media all and (max-width : 330px) {

	nav ul li {
		display:block;
		width: 94%;
    float: left;
	}

}

