.navbar {
  background: linear-gradient(to right, #00544e, #009696);
  height: 5vh;
  width: 100vw;
  display: flex;
  align-items: center;

  .navbar_left {
    width: 50vw;
    display: flex;
    margin-left: 2.5vw;
    font-size: 1.2rem;
    font-weight: 500;
    color: white;
    letter-spacing: 2px;
    background-color: transparent;
  }

  .navbar_right {
    width: 50vw;
    display: flex;
    justify-content: flex-end;
    margin-right: 2vw;
    font-size: 1.2rem;
    font-weight: 500;
    background-color: transparent;

    .navbar_right_button {
      border: none;
      background-color: transparent;
      font-size: 0.8em;
      font-family: 'Nunito';
      font-weight: 500;
      color: white;
    }
  }
}

header {
  background-image: url('../../assets/bg-header.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  flex-grow: 1;
  height: 200px;
  color: #fff;
  text-shadow: 0px 0px 15px black;
  filter: brightness(110%);
}

@media (max-width: 625px) {
  .navbar {
    background: linear-gradient(to right, #00544e, #009696);
    height: 5vh;
    width: 100vw;
    display: flex;
    align-items: center;

    .navbar_left {
      width: 50vw;
      display: flex;
      margin-left: 5vw;
      font-size: 0.8em;
      font-weight: 500;
      color: white;
      background-color: transparent;
    }
  }
}

@media (max-width: 700px) {
  header {
    background-image: url('../../assets/bg-header.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    flex-grow: 1;
    height: 400px;
    color: #fff;
    text-shadow: 0px 0px 15px black;
    filter: brightness(110%);
  }
}

@media (max-width: 550px) {
  header {
    background-image: url('../../assets/bg-header.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    flex-grow: 1;
    height: 400px;
    color: #fff;
    text-shadow: 0px 0px 15px black;
    filter: brightness(110%);
  }
}
