@media (min-width: 1250px) {
  .loginAside {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    height: 100vh;
    width: 100vw;
    background-image: url(../../assets/bg-login1.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .loginForm {
    width: 25vw;
    height: 45vh;
    justify-content: center;
    align-items: center;
    background-color: #00544e;
    padding: 10px 40px;
    overflow: auto;
    border-radius: 15px;
    overflow: hidden;
  }

  .formLogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5vh;
    padding-bottom: 5vh;
  }

  .formLogo {
    width: 15vw;
    filter: drop-shadow(2px 2px 5px black);
  }

  .formTitle {
    color: #707c8b;
    font-weight: 300;
    margin-bottom: 50px;
  }

  .formCenter {
    margin-bottom: 50px;
  }

  .formField {
    margin-bottom: 4vh;
  }

  .formFieldInput {
    width: 100%;
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    border-bottom: 1px solid rgb(214, 214, 214);
    font-size: 1em;
    font-weight: 300;
    padding-bottom: 1vh;
    position: relative;
  }

  .formFieldInput::placeholder {
    color: #8fa3bd;
  }
}

@media (max-width: 1249px) and (min-width: 769px) {
  .loginAside {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    height: 100vh;
    width: 100vw;
    background-image: url(../../assets/bg-login1.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .loginForm {
    width: 50vw;
    height: 40vh;
    justify-content: center;
    align-items: center;
    background-color: #00544e;
    padding: 10px 40px;
    overflow: auto;
    border-radius: 15px;
  }

  .formLogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5vh;
    padding-bottom: 5vh;
  }

  .formLogo {
    width: 15vw;
    filter: drop-shadow(2px 2px 5px black);
  }

  .formTitle {
    color: #707c8b;
    font-weight: 300;
    margin-bottom: 50px;
  }

  .formCenter {
    margin-bottom: 50px;
  }

  .formField {
    margin-bottom: 4vh;
  }

  .formFieldInput {
    width: 100%;
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    border-bottom: 1px solid rgb(214, 214, 214);
    font-size: 1em;
    font-weight: 300;
    padding-bottom: 1vh;
    position: relative;
  }

  .formFieldInput::placeholder {
    color: #8fa3bd;
  }
}

@media (max-width: 768px) {
  .loginForm {
    background-color: #00544e;
    height: 100vh;
    width: 100vw;
    padding: 25px 40px;
    overflow: auto;
  }

  .formLogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20vh;
    padding-bottom: 10px;
    position: relative;
  }

  .formLogo {
    margin: 2rem 0;
    width: 45vw;
    filter: drop-shadow(2px 2px 5px black);
  }

  .formCenter {
    margin-bottom: 100px;
  }

  .formField {
    margin-bottom: 50px;
  }

  .formFieldLabel {
    display: block;
    text-transform: uppercase;
    font-size: 0.9em;
    color: white;
  }

  .formFieldInput {
    width: 100%;
    background-color: #00000050;
    border: none;
    color: white;
    outline: none;
    border-bottom: 1px solid #ffffff;
    font-size: 1em;
    font-weight: 300;
    padding-bottom: 10px;
    margin-top: 10px;
    position: relative;
    box-shadow: 0 0 10px rgb(0, 0, 0);
    filter: drop-shadow(2px 2px 5px black);
  }

  .formFieldInput::placeholder {
    color: #ffffff;
  }
}

.formFieldButton {
  position: relative;
  display: flex;
  padding: 20px 5px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 0.7vh;
  overflow: hidden;
  color: rgb(0, 150, 150);
  cursor: pointer;
  letter-spacing: 2px;
  border: none;
  transition: all ease 1s;
  background: rgb(255, 255, 255);
  font-size: 1em;
  font-weight: 300;
}

.formFieldButton::after,
.formFieldButton::before {
  width: 35px;
  height: 200px;
  content: '';
  display: block;
  background: rgba($color: white, $alpha: 0.5);
  transform: rotate(45deg);
  top: -60px;
  position: absolute;
  transition: none;
  right: -45%;
  cursor: pointer;
}

.formFieldButton::before {
  left: -55%;
}

.formFieldButton:hover {
  text-shadow: 0px 0px 10px rgb(255, 255, 255);
  color: #ffffff;
  background: #249889;
  filter: drop-shadow(3px 3px 5px rgb(0, 107, 107));
}

.formFieldButton:hover::after,
.formFieldButton:hover::before {
  transform: rotate(45deg), scaleX(130%);
  background: rgba($color: white, $alpha: 0.5);
  transition: all ease 1s;
}

.formFieldButton:hover::after {
  right: 130%;
}

.formFieldButton:hover::before {
  left: 130%;
}

.formTitleText {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  color: white;
  text-decoration: none;
  display: inline-block;
  font-size: 1.7em;
  margin: 0 10px;
  padding-bottom: 10px;
  position: relative;
  text-shadow: 0 0 10px rgb(0, 0, 0);
  filter: drop-shadow(2px 2px 4px black);
}
