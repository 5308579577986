$font: Arial, Helvetica, sans-serif;

//Global Colors
$white: #fff;
$grey: #222222;
$lightGrey: #d8d8d8;
$bgGrey: #363636;
$shadowGrey: rgba(0, 0, 0, 0.4);
$placeInput: #f5f5fb;
$placeInput-strong: #e9e9e9;
$primary: #309010;
$secondary: #ffffff;
$contrast: #d4445b;
$primary-strong: #0b8f8d;
$contrast-strong: #c02942;
$primary-light: #11cac7;
$primary-alpha: rgba(14, 179, 175, 0.5);
$secondary-alpha: rgba(160, 186, 87, 0.5);
$contrast-alpha: rgba(212, 68, 91, 0.5);

//Media Queries
$phone: 400px;
$tablet: 768px;
$desktop: 1200px;

