@import './mixins';
@import './settings';
@import 'components/login.scss';
@import 'components/loader.scss';
@import 'components/information.scss';
@import 'components/navbar.scss';
@import 'components/footer.scss';
@import 'components/menu.scss';
@import 'components/places.scss';
@import 'components/form.scss';

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  scroll-behavior: smooth;

  background-image: url('../assets/bg-login.svg');
  background-size: cover; /* Hace que la imagen cubra toda la pantalla */
  background-position: center; /* Centra la imagen en la pantalla */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-attachment: fixed;  /* Fija la imagen al fondo */
  height: 100vh;
  
}

html::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: inherit;
  background-size: inherit;
  background-position: inherit;
  background-repeat: inherit;
  filter: blur(2px); /* Ajusta el valor para aumentar o reducir el desenfoque */
  z-index: -1; /* Coloca el pseudo-elemento detrás del contenido */
}

*,
*::after,
*::before {
  box-sizing: inherit;
  list-style: none;
  text-decoration: none;
}

body {
  margin: 0;
  overflow-x: hidden;
  height: 100%;
}

ul,
ol {
  padding: 0px;
}

a:visited,
a:link,
a:focus,
a:hover,
a:active {
  list-style: none;
  text-decoration: none;
}

.my-swal {
  z-index: 100000;
}

.with-navbar{
  padding-top: 3%;
}

.login {
  padding-top: -3%;
}

/* Contenedor principal para que el footer se mantenga al final */
.app-container {
  display: flex;
  flex-direction: column; /* Alinear elementos en columna */
  min-height: 100vh; /* Asegúrate de que ocupe al menos la altura de la ventana */
}

.main-content {
  flex: 1; /* Este espacio toma todo el espacio disponible */
}

@media all and (max-width : 982px) {
  .with-navbar{
    padding-top: 10%;
  }
}

@media all and (max-width : 890px) {
  .with-navbar{
    padding-top: 15%;
  }
}

@media all and (max-width : 600px) {
  .with-navbar{
    padding-top: 30%;
  }
}
